'use client';

import { Fragment, useRef, useState } from 'react';
import Link from 'next/link';
import { AnimatePresence, motion } from 'framer-motion';
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '../ui/dropdown-menu';

import ExpandMore from '@material-symbols/svg-400/outlined/keyboard_arrow_down.svg';
import ExpandLess from '@material-symbols/svg-400/outlined/keyboard_arrow_up.svg';

const NavLinks = ({ footer = false }: { footer?: boolean }) =>
{
	const [platformMenuOpen, setPlatformMenuOpen] = useState(false);

	const links = [
		['Research', '/research/page/1'],
		['Plans', '/pricing']
	];

	const squeezeLinks = [
		['Workflows', '/workflows'],
		['Atlas', '/atlas'],
		['Compass', '/compass'],
		['Assistant', '/assistant']
	];

	if (footer)
	{
		links.push(['Privacy Policy', '/privacy']);
		links.push(['Terms of Service', '/terms']);
		links.push(['Changelog', '/changelog']);
	}

	return (
		<div className='flex flex-row items-center gap-8'>
			<DropdownMenu open={platformMenuOpen} onOpenChange={setPlatformMenuOpen}>
				<DropdownMenuTrigger className='text-neutral-700 flex flex-row items-center gap-2 hover:text-maven-primary-700 hover:underline active:text-maven-primary-700 active:underline transition-colors duration-300'>
					<p>Platform</p>
					<span className='text-icon'>{platformMenuOpen ? <ExpandLess /> : <ExpandMore />}</span>
				</DropdownMenuTrigger>
				<DropdownMenuContent className='flex flex-col gap-2 p-0 '>
					{squeezeLinks.map(([label, href], index) => (
						<Link
							key={label}
							href={href}
							className='text-neutral-700 hover:text-maven-primary-700 hover:underline transition-colors duration-300 px-3 py-2 hover:bg-neutral-100'
						>
							<span className='relative z-10'>{label}</span>
						</Link>
					))}
				</DropdownMenuContent>
			</DropdownMenu>
			<Fragment>
				{links.map(([label, href], index) => (
					<Link key={label} href={href} className='text-neutral-700 hover:text-maven-primary-700 hover:underline transition-colors duration-300'>
						<span className='relative z-10'>{label}</span>
					</Link>
				))}
			</Fragment>
		</div>
	);
};

export { NavLinks };

export function NavLinksOld({ footer = false }: { footer?: boolean })
{
	let [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
	let timeoutRef = useRef<number | null>(null);

	const links = [
		['Our Research', '/research/page/1'],
		['How We Help', '/#features'],
		['What We Offer', '/#secondary-features'],
		['Plans', '/pricing']
		//['Request Trial', 'mailto:amurthy@mavenbio.io'],
		//['Get a Demo', 'https://cal.com/team/maven-bio/intro']
	];

	if (footer)
	{
		links.push(['Privacy Policy', '/privacy']);
		links.push(['Terms of Service', '/terms']);
		links.push(['Changelog', '/changelog']);
	}

	return links.map(([label, href], index) => (
		<Link
			key={label}
			href={href}
			className='relative -mx-3 -my-2 rounded-lg px-3 py-2 text-base text-gray-700 transition-colors delay-150 hover:text-gray-900 hover:delay-0'
			onMouseEnter={() =>
			{
				if (timeoutRef.current)
				{
					window.clearTimeout(timeoutRef.current);
				}
				setHoveredIndex(index);
			}}
			onMouseLeave={() =>
			{
				timeoutRef.current = window.setTimeout(() =>
				{
					setHoveredIndex(null);
				}, 200);
			}}
		>
			<AnimatePresence>
				{hoveredIndex === index && (
					<motion.span
						className='absolute inset-0 rounded-lg bg-gray-100'
						layoutId='hoverBackground'
						initial={{ opacity: 0 }}
						animate={{ opacity: 1, transition: { duration: 0.15 } }}
						exit={{
							opacity: 0,
							transition: { duration: 0.15 }
						}}
					/>
				)}
			</AnimatePresence>
			<span className='relative z-10'>{label}</span>
		</Link>
	));
}
